import { Div, FooterLink, FullWidthBg, Root, Text, Title } from 'components';
import moment from 'moment';
import 'moment/locale/fr';
import React from "react";
import styled from "styled-components";
import { space } from 'styled-system';
import design from "../design";
import BareLogo from "../images/logo.svg";
import BareMenu from "../images/menu.svg";
import MobileNav from './mobileNav.fr';
import Navigation from './nav.fr';
import "./style.scss";

const FullWidthHeader = styled(Div)`
  display: none;
  position: absolute;
  top: 0;
  left: calc(-100%);
  right: calc(100%);
  bottom: 0;
  background: ${({ bg }) => bg ? bg : design.color.primary};
  width: 300%;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`

const ElBareLogo = ({ className }) => (<BareLogo className={className} />)
const Logo = styled(ElBareLogo)`
  height: 28px;
  .a, path {
    fill: #FFF;
  }
  ${space}
`
const Line = styled.div`
  border-top: 1px solid rgba(244, 253, 255, 0.4)
`

const Menu = styled(BareMenu)`
  // padding: ${10}px;
  // height: ${28+10*2}px;
  // margin-right: ${-10}px;
  height: 28px;
  .a, .path {
    fill: #FFF;
    fill: ${design.color.text};
  }
`
const TempLink = ({ to, children, className }) => (<a className={className} href={to}>{children}</a>)
//Link
const NavItem = styled(TempLink)`
  // background: gray;
  cursor: pointer;
  font-size: 18px;
  font-weight: ${({ bold }) => bold ? 700 : 400};
  color: #FFF;
  color: ${design.color.text};
  text-decoration: none;
  margin-right: ${48-10}px;
  ${({ marginLeft }) => marginLeft ? `margin-left: ${marginLeft}px;` : ``}
`
const Nav = styled.nav`
  // background: red;
  display: flex;
  align-items: center;
  ${NavItem} {
    padding: 10px;
  }
`

export default class IndexFr extends React.Component {
  constructor(props) {
    super(props)
    moment.locale('fr');
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll()
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = e => {
    if (typeof window !== 'undefined') {
      if (window.scrollY < 40) {
        document.getElementById('sticky').classList.remove('white')
      } else {
        document.getElementById('sticky').classList.add('white')
      }
      console.log('scroll', window.scrollY)
      // this.setState({scroll: window.scrollY});
    }
  }

  render() {
    const { children, className, gradient } = this.props
    return (
      <Root gradient={gradient} className={className}>
        <Div p={[`24px 36px`,`24px 48px`]}>
          <Div userSelect={'none'} id={'sticky'} maxWidth={1280} margin={'0 auto'} display={'flex'} color={'text'} bg={[gradient ? 'transparent' : '#F4FDFF', 'transparent']} _boxShadow={['none','none']} top={0} left={0} right={0} p={[`8px 36px`,`24px 48px`]} position={['fixed','fixed']} style={{ zIndex:5 }} justifyContent={'space-between'} alignItems='center'>
            <FullWidthHeader display={['none','block']} className={'fullwh'} bg={gradient ? 'transparent' : '#F4FDFF'} />
            <Navigation home={gradient} />
            <Div display={'flex'} zIndex={1}>
              <Div display={['block','none']}>
                <MobileNav home={gradient} />
              </Div>
            </Div>
          </Div>
          {children}
        </Div>
        <footer>
          <Div position={'relative'} color={'white'} bg={'primary'} mt={[4,8]}>
            <FullWidthBg />
            <Div display={['block', 'flex']}>
              <Div tag={'address'} p={[36,6]} ml={[0,6]}>
                <Text tag={'span'} display={'inline-block'} lineHeight={1.1} fontWeight={700} position={'relative'} mb={[5,4]} fontSize={6}>
                  <Div tag={'span'} mr={[14,0]} display={'inline-block'} position={['static','absolute']} top={0} left={-36}><Logo mt={[0,`-2px`]} mb={[`-4px`,0]} /></Div>
                  Stairlin
                </Text>
                <Text mb={4}>Avenue du Théâtre 1<br/>
                  1005 Lausanne<br/>
                  Suisse</Text>
                <Text mb={4}><a href="mailto:info@stairlin.com">info@stairlin.com</a><br/>
                  <a href="tel:+41215391832">+41 (0)21 539 18 32</a></Text>
                <Text>VAT CHE-230.644.686</Text>
              </Div>
              <Div display={['block', 'flex']} p={[36,6]}>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>Général</Title>
                  <FooterLink to={'/fr/about'} mb={[4,2]} fontSize={3}>A propos</FooterLink>
                  <FooterLink to={'/fr/jobs'} mb={[4,2]} fontSize={3}>Emplois</FooterLink>
                  <FooterLink to={'/fr/terms'} mb={[4,2]} fontSize={3}>Conditions générales</FooterLink>
                  <FooterLink to={'/fr/privacy'} mb={[4,2]} fontSize={3}>Politique de confidentialité</FooterLink>
                  <FooterLink to={'/fr/faq'} mb={7} fontSize={3}>FAQ</FooterLink>
                </Div>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>Marchand</Title>
                  <FooterLink to={'/fr/support'} mb={7} fontSize={3}>Support</FooterLink>
                </Div>
                <Div mx={[0,6]}>
                  <Title mb={[5,4]} fontSize={6}>Clients</Title>
                  <FooterLink to={'/fr/support'} mb={7} fontSize={3}>Assistance</FooterLink>
                </Div>
              </Div>
            </Div>
            <Line/>
            <Div px={[36,6]} py={4} display={['block','flex']} justifyContent={'space-between'}>
              <Div>
                © 2020 Stairlin SA
              </Div>
              <Div mt={[4,0]} display={'flex'}>
                <Text mr={6}><a style={{ color: 'white' }} href={`/en`}>English</a></Text>
                <Text fontWeight={700}>Français</Text>
                {/*<Text>Deutsch</Text>*/}
              </Div>
            </Div>
          </Div>
        </footer>
      </Root>
    )
  }
}
