import { Div, Link, Title } from 'components';
import React from 'react';
import styled from 'styled-components';
import design from "../design";
import BareClose from "../images/close.svg";
import BareLogo from "../images/logo.svg";
import BareMenu from "../images/menu.svg";

const Logo = styled(BareLogo)`
  height: 28px;
  .a {
    //fill: #FFF;
    fill: ${design.color.text};
  }
`
const Menu = styled(BareMenu)`
  cursor: pointer;
  // padding: ${10}px;
  // height: ${28+10*2}px;
  // margin-right: ${-10}px;
  height: 28px;
  .a, .path {
    //fill: #FFF;
    fill: ${({ home }) => home ? design.color.white : design.color.text};
  }
`
const Close = styled(BareClose)`
  cursor: pointer;
  height: 28px;
  .a {
    fill: ${design.color.text};
  }
`

const FullScreen = styled.div`
  position: fixed;
  width: 375px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: white;
  z-index: 20;
  overflow: scroll;
`

const Rounded = styled.span`
  color: ${design.color.white};
  background: ${design.color.primary};
  border-radius: 24px;
  padding: 4px 8px;
  font-size: 12px;
  margin-left: 8px;
  vertical-align: middle;
  display: inline-block;
  font-weight: 700;
`

class MobileNav extends React.Component {
  state = {
    open: false,
  }

  open = e => {
    this.setState({ open: true })
    try {
      document.body.classList.add('menu')
    } catch(e){}
  }
  close = e => {
    try {
      this.setState({ open: false })
      document.body.classList.remove('menu')
    } catch(e){}
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.__closeMobileNav = this.close
    }
  }

  componentWillUnmount() {
    try {
      document.body.classList.remove('menu')
    } catch(e){}
  }

  render() {
    return (
      <div>
        {this.state.open ? (
          <FullScreen>
            <Div tag={'nav'} p={`8px 36px`}>
              <Div py={`10px`} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Div display={'flex'} alignItems={'center'}>
                  <Logo /><Title ml={2} fontSize={3}>Stairlin</Title>
                </Div>
                <Close onClick={this.close} />
              </Div>
              <Div mt={5}>
                <Link display={'block'} to={`/fr`} fontSize={3} mb={5}>Aperçu</Link>
                <Link display={'block'} to={`/fr/finance`} fontSize={3} mb={5}>Facturation / Comptabilité</Link>
                <Link display={'block'} to={`/fr/schedule`} fontSize={3} mb={5}>Calendrier / Réservations</Link>
                <Link display={'block'} to={`/fr/customers`} fontSize={3} mb={5}>Interaction clients</Link>
                <Link display={'block'} to={`/fr/payment`} fontSize={3} mb={5}>Paiements en ligne<Rounded>Prochainement</Rounded></Link>
              </Div>
              <Div mt={5} display={'flex'}>
                <Div flex={1}>
                  <Link display={'block'} to={`/fr/pricing`} fontSize={2} mr={5} mb={5}>Tarifs</Link>
                  <Link display={'block'} to={`/fr/learn`} fontSize={2} mr={5} mb={5}>Apprendre</Link>
                </Div>
                <Div flex={1}>
                  <Link display={'block'} to={`/fr/support`} fontSize={2} mr={5} mb={5}>Assistance</Link>
                  <Link display={'block'} to={`/fr/about`} fontSize={2} mr={5} mb={5}>A propos</Link>
                  {/*<Link display={'block'} to={`/en`} fontSize={2} mr={5} mb={5}>English</Link>*/}
                </Div>
              </Div>
            </Div>
          </FullScreen>
        ) : <Menu className={'st'} home={this.props.home} onClick={this.open} />}
      </div>
    )
  }
}

export default MobileNav
